<template>
  <div class="sentences container pb-6">
    <div v-if="selectStore.projectsCards.length === 0">
      <article class="message is-primary">
        <div class="message-body">
          This pack is empty
        </div>
      </article>
    </div>
    <div
      v-for="card in selectStore.projectsCards" v-bind:key="card.sentenceId"
      @click="onCardClick(card)"
      class="block">
      <EzCard
        :card="card" :isSelected="selectedCardSentenceId === card.sentenceId" />
    </div>
  </div>
  <div class="buttons has-background-grey-light p-2" v-if="selectStore.projectsCards.length > 0">
    <div class="container is-flex">
      <EzExportCards v-if="!selectedCardSentenceId"></EzExportCards>
      <EzEditCard
        v-if="!!selectedCardSentenceId"
        @updated="onCardUpdated"
        :card="selectStore.projectsCard(selectedCardSentenceId)">
      </EzEditCard>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EzCard from '@/components/cards/EzCard.vue';
import EzEditCard from '@/components/cards/EzEditCard.vue';
import EzExportCards from '@/components/cards/EzExportCards.vue';
import { Card, useSelectStore, SentenceId } from '@/stores/select.store';

@Options({
  components: {
    EzEditCard,
    EzExportCards,
    EzCard,
  },
})
export default class EditCards extends Vue {
  @Prop({ isRequired: true }) packId!: string;

  selectedCardSentenceId: SentenceId | null = null;

  selectStore = useSelectStore();

  async onCardUpdated(newContent: Card): Promise<void> {
    if (!this.selectedCardSentenceId) {
      return; // never happens
    }
    this.selectStore.updateCard(this.selectedCardSentenceId, newContent);
    this.selectedCardSentenceId = null;
  }

  onCardClick(card: Card): void {
    if (this.selectedCardSentenceId === card.sentenceId) {
      this.selectedCardSentenceId = null;
    } else {
      this.selectedCardSentenceId = card.sentenceId;
    }
  }
}
</script>
<style scoped lang="scss">
.buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
