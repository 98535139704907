
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Card } from '@/stores/select.store';

@Options({
  components: {
  },
})
export default class EzCard extends Vue {
  @Prop({ required: true }) card!: Card;

  @Prop({ required: false }) isSelected: undefined;
}
