
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EzCard from '@/components/cards/EzCard.vue';
import EzEditCard from '@/components/cards/EzEditCard.vue';
import EzExportCards from '@/components/cards/EzExportCards.vue';
import { Card, useSelectStore, SentenceId } from '@/stores/select.store';

@Options({
  components: {
    EzEditCard,
    EzExportCards,
    EzCard,
  },
})
export default class EditCards extends Vue {
  @Prop({ isRequired: true }) packId!: string;

  selectedCardSentenceId: SentenceId | null = null;

  selectStore = useSelectStore();

  async onCardUpdated(newContent: Card): Promise<void> {
    if (!this.selectedCardSentenceId) {
      return; // never happens
    }
    this.selectStore.updateCard(this.selectedCardSentenceId, newContent);
    this.selectedCardSentenceId = null;
  }

  onCardClick(card: Card): void {
    if (this.selectedCardSentenceId === card.sentenceId) {
      this.selectedCardSentenceId = null;
    } else {
      this.selectedCardSentenceId = card.sentenceId;
    }
  }
}
