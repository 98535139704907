<template>
  <div class="card-edit">
    <div class="field has-addons">
      <button class="button is-primary mr-2" v-on:click="onEditClick()">Edit card</button>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <label class="label">Front</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  ref="cardFrontRef"
                  placeholder="E.g. ez egy mondat"
                  v-model="front"
                  :disabled="isSaving"
                />
                <p v-if="frontError" class="help is-danger">{{ frontError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Back</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="E.g. This is a sentence"
                  v-model="back"
                  :disabled="isSaving"
                />
                <p v-if="frontError" class="help is-danger">{{ frontError }}</p>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  v-bind:class="isSaving ? 'is-loading' : ''"
                >
                  Save
                </button>
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button"
                  v-if="!isSaving"
                  v-on:click="onCancelClick()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Card } from '@/stores/select.store';

@Options({
  emits: ['updated'],
})
export default class EzEditCard extends Vue {
  @Prop({ isRequired: true }) card!: Card;

  front = '';

  back = '';

  showForm = false;

  isSaving = false;

  frontError = '';

  backError = '';

  created(): void {
    this.front = this.card.sentence;
    this.back = this.card.translation;
  }

  onEditClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.cardFrontRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateFront(): void {
    this.frontError = !this.front.trim() ? 'Front should be filled' : '';
  }

  validateBack(): void {
    this.backError = !this.front.trim() ? 'Back should be filled' : '';
  }

  async handleSubmit(): Promise<void> {
    this.validateFront();

    this.validateBack();

    if (this.frontError || this.backError) {
      return;
    }
    const newContent = {
      sentence: this.front,
      translation: this.back,
    };
    this.$emit('updated', newContent);
    this.showForm = false;
  }
}
</script>
