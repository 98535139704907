
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Card } from '@/stores/select.store';

@Options({
  emits: ['updated'],
})
export default class EzEditCard extends Vue {
  @Prop({ isRequired: true }) card!: Card;

  front = '';

  back = '';

  showForm = false;

  isSaving = false;

  frontError = '';

  backError = '';

  created(): void {
    this.front = this.card.sentence;
    this.back = this.card.translation;
  }

  onEditClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.cardFrontRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateFront(): void {
    this.frontError = !this.front.trim() ? 'Front should be filled' : '';
  }

  validateBack(): void {
    this.backError = !this.front.trim() ? 'Back should be filled' : '';
  }

  async handleSubmit(): Promise<void> {
    this.validateFront();

    this.validateBack();

    if (this.frontError || this.backError) {
      return;
    }
    const newContent = {
      sentence: this.front,
      translation: this.back,
    };
    this.$emit('updated', newContent);
    this.showForm = false;
  }
}
