<template>
  <div class="card-edit">
    <div class="field has-addons">
      <a
        class="button is-primary mr-2"
        v-bind:href="href"
        v-on:click="onExportClick()"
        download="cards"
        target="_blank">
          Export cards
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { useSelectStore } from '@/stores/select.store';

@Options({
  emits: ['updated'],
})
export default class EzExportCards extends Vue {
  href = '';

  selectStore = useSelectStore();

  private getContent(): string {
    console.log(this.selectStore.projectsCards);
    const lines = this.selectStore.projectsCards.map(
      (card) => [card.sentence, card.sentence].join('\r\n'),
    );
    return lines.join('\r\n');
  }

  onExportClick(): void {
    this.href = window.URL.createObjectURL(new Blob([this.getContent()], { type: 'text/plain' }));
  }
}
</script>
